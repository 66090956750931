function Messages() {
  return (
    <>
      <div class="row">
        <div class="col-12 col-xl-8">
          <h2 class="solomon-page-titles">Messages</h2>
        </div>
        <div class="col-12 col-xl-4 pt-2">
          <input type="search" class="textbox"></input>

          <input
            type="button"
            class="solomon-high-blue solomon-pill"
            value="Search"
          ></input>
        </div>
      </div>
      <hr />
    </>
  );
}

export default Messages;
