function FamilyMember({ familyMemberContext }) {
  const taskStatus =
    familyMemberContext.tasksCompleteCount +
    " / " +
    familyMemberContext.totalTasksCount +
    " tasks complete";
  
  const upcomingEventText =
    familyMemberContext.upcomingEventsCount > 1
    ? " upcoming events"
    : " upcoming event";
  
  const eventStatus =
    familyMemberContext.upcomingEventsCount + upcomingEventText;

  return (
    <>
      <div class="row mb-4">
        <div class="col-4 pt-3">
          <img class="family-section-profile-image" src={familyMemberContext.photo}></img>
        </div>
        <div class="col-8">
          <div class="row">
            <div class="col-12">
              <h6 class="solomon-page-detail mb-0 fw-bold">{familyMemberContext.name}</h6>
            </div>
            <div class="col-12">
              <h6 class="solomon-page-detail mb-0 text-success">{taskStatus}</h6>
            </div>
            <div class="col-12">
              <p class="mb-0">{eventStatus}</p>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-6">
                  <input
                    type="button"
                    class="solomon-high-blue solomon-pill"
                    value="Send Message"
                  ></input>
                </div>
                <div class="col-6">
                  <input
                    type="button"
                    class="solomon-light-blue solomon-pill"
                    value="Celebrate"
                  ></input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FamilyMember;
