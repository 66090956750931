import { useState } from 'react';
import "./App.css";
import Core from "./pages/Core/Core";
import Login from "./pages/Login/Login";
import { context } from './app_context';

function App() {
  const [auth, setAuth] = useState(false);
  const [appContext, setAppContext] = useState(context);

  function handleEnterButtonClick() {
    setAppContext(appContext);
    setAuth(true);
  }

  function handleAppContextUpdate(newAppContext) {
    setAppContext(newAppContext)
  }

 function handleLogoutButtonClick() {
    setAuth(false);
    setAppContext({});
  }

  if (auth) {
    return <Core appContext={appContext} onLogOutButtonClick={() => handleLogoutButtonClick()} onAppContextUpdate={() => handleAppContextUpdate()}/>;
  } else {
    return <Login onEnterButtonClick={() => handleEnterButtonClick()} />;
  }
}

export default App;
