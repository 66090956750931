import nia from "../src/images/Family/nia.jpg";
import michael_sr from "../src/images/Family/michael_sr.jpg";
import jasmine from "../src/images/Family/jasmine.jpg";
import naomi from "../src/images/Family/naomi.png";
import marley from "../src/images/Family/marley.jpg";
import michael_jr from "../src/images/Family/michael_jr.jpg";

export const context = {
  name: "Michael Sr",
  page: "House",
  photo: michael_sr,
  family: [
    {
      name: "Michael Jr",
      photo: michael_jr,
      totalTasksCount: 10,
      tasksCompleteCount: 3,
      upcomingEventsCount: 1,
    },
    {
      name: "Jasmine",
      photo: jasmine,
      totalTasksCount: 13,
      tasksCompleteCount: 11,
      upcomingEventsCount: 3,
    },
    {
      name: "Marley",
      photo: marley,
      totalTasksCount: 6,
      tasksCompleteCount: 5,
      upcomingEventsCount: 4,
    },
    {
      name: "Nia",
      photo: nia,
      totalTasksCount: 7,
      tasksCompleteCount: 4,
      upcomingEventsCount: 3,
    },
    {
      name: "Naomi",
      photo: naomi,
      totalTasksCount: 4,
      tasksCompleteCount: 2,
      upcomingEventsCount: 2,
    },
  ],
  notifications: [
    {
      text: "Your voting booth closed",
      timestamp: "6:00 PM",
      dismissed: false
    },
    {
      text: "There is a voting booth waiting for your vote closing soon",
      timestamp: "7:00 PM",
      dismissed: false
    },
    {
      text: "Jasmine accepted your request",
      timestamp: "2:24 PM",
      dismissed: false
    },
    {
      text: "Nia nudged you on a request",
      timestamp: "3:49 PM",
      dismissed: false
    },
    {
      text: "You have 9 Tasks, 4 Events, and 2 Chores for the day",
      timestamp: "5:00 AM",
      dismissed: false
    },
    {
      text: "You have 6 requests for today",
      timestamp: "5:00 AM",
      dismissed: false
    },
  ],
  requests: [],
  tasks: [],
  goals: [],
  messages: [],
  announcements: [],
  votingBooths: [],
  events: [],
  chores: [],
};
