import solomon_house_logo from "../../images/Logos/solomon_house_logo.png";

function Login({onEnterButtonClick}) {
  return (
    <div class="p-2 text-center">
      <div class="mt-5 mb-2">
        <img
          src={solomon_house_logo}
          class="img-fluid"
          height="auto"
          width="25%"
          alt="Solomon House Logo"
        />
      </div>
      <div class="mb-5">
        <h1 class="login-page-family-name">SOLOMON HOUSE</h1>
      </div>
      <div class="mt-5 pt-5 px-5 w-50 d-inline-block">
        <form>
          <div class="mb-3 px-5">
            <input
              type="textbox"
              class="form-control text-center textbox"
              id="loginInputUsername"
              placeholder="username"
            />
          </div>
          <div class="mb-5 px-5">
            <input
              type="password"
              class="form-control text-center textbox"
              placeholder="password"
              id="loginInputPassword"
            />
          </div>
          <button type="submit" class="btn solomon-mid-blue solomon-button" onClick={onEnterButtonClick}>
            ENTER
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
