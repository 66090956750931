import FamilyMember from "../../components/Family/FamilyMember";

function FamilySection({ familyContextArray }) {


  return (
    <>
    <div class="solomon-component-box px-2 py-4">
     <ul class="p-3">
        {familyContextArray.map(familyMember => (
          <FamilyMember familyMemberContext={familyMember} />
        ))}
      </ul>
      </div>
    </>
  );
}

export default FamilySection;
