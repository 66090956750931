import { useState } from "react";
import House from "../Core/House/House";
import Family from "../Core/Family/Family";
import Messages from "../Core/Messages/Messages";
import Profile from "../Core/Profile/Profile";
import SideBar from "../../sections/SideBar/Sidebar";

function Core({ appContext, onLogOutButtonClick, onAppContextUpdate }) {
  let context = appContext;
  console.log(context);

  const [pageContent, setPageContent] = useState(<House context={context} />);

  const [sideBarContent, setSideBarContent] = useState(<SideBar context={context} /> );

  const photo = appContext.photo;

  function handleHouseNavClick() {
    context.page = "House";

    setSideBarContent(<SideBar context={context} />);

    setPageContent(<House context={context} />);
  }

  function handleFamilyNavClick() {
    context.page = "Family";

    setSideBarContent(<SideBar context={context} />);
    setPageContent(<Family context={context} />);
  }

  function handleMessagesNavClick() {
    context.page = "Messages";

    setSideBarContent(<SideBar context={context} />);
    setPageContent( <Messages context={context} />);
  }

  function handleProfileNavClick() {
    context.page = "Profile";

    console.log(context.page);
    setSideBarContent(<SideBar context={context} />);

    setPageContent(<Profile context={context} />);
  }

  return (
    <div>
      <div className="App mb-5">
        <nav class="navbar navbar-light navbar-expand-lg bg-body-tertiary">
          <div class="container-fluid">
            <a class="navbar-brand solomon-family-name me-5">SOLOMON HOUSE</a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle mx-5"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={photo}
                      alt="Bootstrap"
                      width="30px"
                      height="30px"
                      class="me-1 nav-bar-profile-image"
                    ></img>
                    {context.name}
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        class="dropdown-item"
                        onClick={() => handleProfileNavClick()}
                      >
                        Goals
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        onClick={() => handleProfileNavClick()}
                      >
                        Settings
                      </a>
                    </li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        onClick={() => onLogOutButtonClick()}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link active mx-4"
                    aria-current="page"
                    onClick={() => handleHouseNavClick()}
                  >
                    HOUSE
                  </a>
                </li>
                <li class="nav-item mx-4">
                  <a class="nav-link" onClick={() => handleFamilyNavClick()}>
                    FAMILY
                  </a>
                </li>
                <li class="nav-item mx-4">
                  <a class="nav-link" onClick={() => handleMessagesNavClick()}>
                    MESSAGES
                  </a>
                </li>
                <li class="nav-item mx-4">
                  <a class="nav-link" onClick={() => handleProfileNavClick()}>
                    PROFILE
                  </a>
                </li>
              </ul>

              <button class="btn solomon-button solomon-mid-blue" type="submit">
                + NEW
              </button>
            </div>
          </div>
        </nav>
      </div>
      <div class="container mt-5">
        <div class="row">
          <div class="col-xl-9">{pageContent}</div>
          <div class="col-xl-3">{sideBarContent}</div>
          </div>
      </div>
    </div>
  );
}

export default Core;
