function Notification({ notificationContext }) {
  return (
    <>
      <div class="row mb-3">
        <div class="col-11 solomon-sub-component">
          <h6 class="solomon-page-detail fw-bold">
            {notificationContext.text}
            <span class="fw-light"> at {notificationContext.timestamp}</span>
          </h6>
        </div>
        <div class="col-1">
          <input type="button" class="btn-close"></input>
        </div>
      </div>
    </>
  );
}

export default Notification;
