import Notification from "../../components/Notification/Notification";

function NotificationSection({ notificationContextArray }) {


  return (
    <>
    <div class="solomon-component-box medium-component container">
      <h5 class="fw-bold">Notifications</h5>
     <ul class="p-3">
        {notificationContextArray.map(notification => (
          <Notification notificationContext={notification} />
        ))}
      </ul>
      </div>
    </>
  );
}

export default NotificationSection;
