import { getDateValue } from "../../../utils/date_util";

function Family() {
  let today = getDateValue();

  return (
    <>
    <div class="row">
      <div class="col-9">
        <h2 class="solomon-page-titles">Announcements</h2>
      </div>
      <div class="col-3 pt-2">
        <h6 class="solomon-page-detail">for {today}</h6>
      </div>
    </div>
    <hr />
    </>
    );
  }
  
  export default Family;