import FamilySection from "../FamilySection/FamilySection";

function SideBar({ context }) {

  if (context.page !== "Profile") {
    return (
      <div className="mb-5">
        <h2 class="solomon-page-titles mb-4">Family</h2>
        <FamilySection familyContextArray={context.family}/>
      </div>
    );
  } else {
    return (
      <>
        <div>
          <h2 class="solomon-page-titles">Your Requests</h2>
        </div>
        <div>
          <h2 class="solomon-page-titles">Your Tasks</h2>
        </div>
      </>
    );
  }
}

export default SideBar;
