import { getDateValue } from "../../../utils/date_util";

function Profile() {
  let today = getDateValue();

  return (
    <>
      <div class="row">
        <div class="col-8">
          <h2 class="solomon-page-titles">Profile</h2>
        </div>
        <div class="col-4 pt-2">
          <h6 class="solomon-page-detail"> 6/9 Tasks complete for {today}</h6>
        </div>
      </div>
      <hr />
    </>
  );
  }
  
  export default Profile;