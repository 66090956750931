import { useState } from "react";
import { getDateValue } from "../../../utils/date_util";
import NotificationSection from "../../../sections/NotificationSection/NotificationSection";

function House({context}) {
  let today = getDateValue();

  return (
    <>
      <div class="row">
        <div class="col-xl-9 col-12">
          <h2 class="solomon-page-titles">Activity</h2>
        </div>
        <div class="col-xl-3 col-12 pt-2">
          <h6 class="solomon-page-detail">for {today}</h6>
        </div>
      </div>
      <hr />
      <div>
      <NotificationSection notificationContextArray={context.notifications}/>
      </div>
    </>
  );
}

export default House;
