function getDayOfTheWeek(number) {
    switch (number) {
      case 0:
        return "SUNDAY";
      case 1:
        return "MONDAY";
      case 2:
        return "TUESDAY";
      case 3:
        return "WEDNESDAY";
      case 4:
        return "THURSDAY";
      case 5:
        return "FRIDAY";
      case 6:
        return "SATURDAY";

      default:
        break;
    }
  }

  export function getDateValue() {
    const date = new Date();

    const dayOfTheWeekNumber = date.getDay();
    const dayNumber = date.getDate();
    const monthNumber = date.getMonth();
    const yearNumber = date.getFullYear();

    const dayOfTheWeek = getDayOfTheWeek(dayOfTheWeekNumber);

    return (
      dayOfTheWeek +
      " " +
      monthNumber.toString() +
      "/" +
      dayNumber.toString() +
      "/" +
      yearNumber.toString()
    );
  }

export function getTimeValue() {
  const date = new Date();

  const hour = date.getHours();
  const minutes = date.getMinutes();

  return (hour.toString() + ":" + minutes.toString());
}
